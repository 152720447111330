@import url("https://fonts.googleapis.com/css?family=Open+Sans:400,600,700&display=swap");

// themes - our custom or/and out of the box themes
@import "themes";

// framework component themes (styles tied to theme variables)
@import '@nebular/theme/styles/globals';
@import '@nebular/auth/styles/globals';

@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/mixins';
@import 'bootstrap/scss/grid';

// loading progress bar theme
@import "./pace.theme";

@import "./layout";
@import "./overrides";

// install the framework and custom global styles
@include nb-install() {
  // framework global styles
  @include nb-theme-global();
  @include nb-auth-global();

  @include ngx-layout();
  // loading progress bar
  @include ngx-pace-theme();

  @include nb-overrides();

  .ng2-smart-title ng2-smart-table-title{
    color: white !important;
  }

  ng2-smart-table table tr td {
    padding: 0px 10px;
  }

  ng2-smart-table nav.ng2-smart-pagination-nav .pagination li a,
  ng2-smart-table nav.ng2-smart-pagination-nav .pagination li > span {
    padding: 0.5rem 0.75rem;
  }

  ng2-smart-table table tr.ng2-smart-titles th {
    padding: 5px 10px;
  }

  ng2-smart-table-pager {
    justify-content: center !important;
  }
}

.form-control {
  color: #495057 !important;
  height: calc(1.5em + 0.5rem + 2px) !important;
  padding: 0.25rem 0.5rem !important;
  font-size: 0.875rem !important;
  line-height: 1.5 !important;
  border-radius: 0.2rem !important;
}

.cursor-pointer {
  cursor: pointer;
}

.example::-webkit-scrollbar {
  display: none;
}

::-webkit-scrollbar {
  width: 0.6em;
}
 
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}
 
::-webkit-scrollbar-thumb {
  background-color: #a16eff;
}

.e-verde td{
  color: #059936 !important;
}

.e-amarillo td{
  color: #f2f213 !important;
}

.e-gris td{
  color: #c9c9c3 !important;
}

.e-rojo td{
  color: #f72100 !important;
}

*{
  outline: none !important;
}

